import React, { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AppBar, Toolbar, Button, MenuList, MenuListItem, Separator, Handle, Frame, Hourglass } from 'react95';
import windowsIcon from '../../assets/windows95.png';
import homeIcon from '../../assets/homepage.png';
import refreshIcon from '../../assets/refresh.png';
import windows95Icon from '../../assets/windows95.png';
import { logout } from '../../services/authService';
import {
  availableTaxReturnsSelector,
  currentTaskSelector,
  taskDetailLoadingSelector
} from '../../selectors/taskDetailSelectors';
import './QDeckToolBar.scss';
import { Link } from 'react-router-dom';
import { userSelector } from '../../selectors/authSelectors';
import { qdeckUsersSelector } from '../../selectors/userSelectors';
import { isCurrentUserAdmin } from '../../utils/taskUtils';
import { useOutsideClick } from '../../utils/componentUtils';
import history from '../../keeperHistory';

const QDeckToolBar = ({
  type,
  menuItems,
  tabs,
  searchItem,
  taskLoading,
  user,
  qdeckUsers,
  logout,
  availableTaxReturns,
  currentTask
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isAdmin = isCurrentUserAdmin(user.email, qdeckUsers);

  const menuRef = useRef(null);
  useOutsideClick(menuRef, () => setMenuOpen(false));

  const year = useMemo(() => {
    if (currentTask) {
      return currentTask.year;
    }
    return null;
  }, [currentTask]);

  const onTaxYearSelect = async (currentYear, selectedYear) => {
    if (currentYear !== selectedYear) {
      const taskId = availableTaxReturns.find((taxReturn) => taxReturn.year === selectedYear).id;
      history.push(`/task-detail/${taskId}`);
    }
  };

  const availableTaxYears = availableTaxReturns.map((taxReturn) => taxReturn.year).sort((a, b) => b - a);
  const taxYearItems = availableTaxYears.map((taxYear) => ({
    name: taxYear,
    primary: year === taxYear,
    onClick: () => onTaxYearSelect(year, taxYear)
  }));

  return (
    <AppBar>
      <Toolbar className='toolbar'>
        <div className='toolbar-left'>
          <div className='toolbar-menu' ref={menuRef}>
            {type === 'task detail' ? (
              <div className='toolbar-menu-expanded'>
                <Button onClick={menuItems[0].onClick} size='lg' className='toolbar-menu-btn'>
                  <img src={homeIcon} alt='home' height={24} />
                  {menuItems[0].name}
                </Button>
                <Handle size={36} />
                <Frame variant='status'>
                  <div style={{ display: 'block', width: 'auto' }}>
                    <Button
                      onClick={() => setMenuOpen(!menuOpen)}
                      active={menuOpen}
                      variant='thin'
                      disabled={taskLoading}
                      className='toolbar-menu-btn'>
                      {menuItems[1].name}
                      {taskLoading ? (
                        <Hourglass size={24} />
                      ) : (
                        <img src={windows95Icon} alt='select year' height={24} />
                      )}
                    </Button>
                    {menuOpen && (
                      <MenuList onClick={() => setMenuOpen(false)} className='toolbar-menu-list'>
                        {taxYearItems &&
                          taxYearItems.map((item) => (
                            <MenuListItem key={item.name} primary={item.primary} onClick={item.onClick}>
                              {item.name}
                            </MenuListItem>
                          ))}
                      </MenuList>
                    )}
                  </div>
                </Frame>
                <Frame variant='status'>
                  <Button
                    onClick={menuItems[1].onClick}
                    variant='thin'
                    disabled={taskLoading}
                    className='toolbar-menu-btn'>
                    {menuItems[2].name}
                    {taskLoading ? <Hourglass size={24} /> : <img src={refreshIcon} alt='refresh' height={24} />}
                  </Button>
                </Frame>
                <Handle size={36} />
              </div>
            ) : (
              <>
                <Button onClick={() => setMenuOpen(!menuOpen)} active={menuOpen} size='lg' className='toolbar-menu-btn'>
                  <img src={windowsIcon} alt='windows95' height={24} />
                  Settings
                </Button>
                {menuOpen && (
                  <MenuList onClick={() => setMenuOpen(false)}>
                    {menuItems &&
                      menuItems.map((item) => (
                        <MenuListItem key={item.name} primary={item.primary} onClick={item.onClick}>
                          {item.name}
                        </MenuListItem>
                      ))}
                    {isAdmin && (
                      <>
                        <Separator />
                        <Link to='/manage-users'>
                          <MenuListItem>Manage Users</MenuListItem>
                        </Link>
                      </>
                    )}
                    <Separator />
                    <MenuListItem onClick={logout}>Log out</MenuListItem>
                  </MenuList>
                )}
              </>
            )}
          </div>

          {tabs && (
            <div className='toolbar-tabs'>
              {tabs.map((tab, i) => (
                <Button
                  key={i}
                  variant='raised'
                  primary
                  active={tab.active}
                  size={tab.active ? 'lg' : 'md'}
                  className={tab.active ? 'selected' : null}
                  onClick={tab.onClick}>
                  {tab.name}
                </Button>
              ))}
            </div>
          )}
        </div>

        {searchItem && <div className='toolbar-search'>{searchItem}</div>}
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  taskLoading: taskDetailLoadingSelector(state),
  user: userSelector(state),
  qdeckUsers: qdeckUsersSelector(state),
  availableTaxReturns: availableTaxReturnsSelector(state),
  currentTask: currentTaskSelector(state)
});

const mapDispatchToProps = {
  logout
};

export default connect(mapStateToProps, mapDispatchToProps)(QDeckToolBar);
